import {
    ButtonHTMLAttributes,
    ReactElement,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import FrameButton from "../buttons/FrameButton";
import {useDispatch} from "react-redux";
import classNames from "classnames";
import {LinkProps, useLocation} from "react-router-dom";
import {
    addError,
    decrementLoading,
    incrementLoading,
    refreshMenuOrderCart,
    removeDpayPriceExpiryTime,
} from "@/redux/meta/metaActions";
import {
    BrandMap,
    HandoffOptions,
    MenuOrder,
    MenuOrderItem,
    MenuOrdersApi, MenuOrderSubItem,
} from "@devour/client";
import getConfig from "../../utils/getConfig";
import {useNavigate} from "react-router";
import DevourCartCard from "./DevourCartCard";
import _, {cloneDeep} from "lodash";
import CartCheckoutButton from "../CartCheckoutButton";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";
import useWindowSize from "../../hooks/useWindowSize";
import {isTablet} from "react-device-detect";
import {TbTrashXFilled} from "react-icons/tb";
import {HiArrowLeft, HiPlus} from "react-icons/hi";
import CheckoutSummaryTotals from "@/components/checkout/CheckoutSummaryTotals";
import CheckoutDetailsHandoff from "@/components/checkout/checkoutDetails/CheckoutDetailsHandoff";
import RestaurantHandoffOptions from "@/pages/restaurants/components/RestaurantHandoffOptions";
import BrandCartPromoCard from "@/components/brands/BrandCartPromoCard";
import CheckoutDiscountSelectionModal from "@/components/checkout/checkoutOrderSummary/CheckoutDiscountSelectionModal";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import {BrandMapStates} from "@/pages/brandMap/context/BrandMapContext";
import {useRestaurantMenu} from "@/hooks/useRestaurantMenu";
import {useRestaurant} from "@/hooks/useRestaurant";
import {useUpdateMenuOrderItems} from "@/hooks/menuOrder/useUpdateMenuOrderItems";
import DevourCartItemRemovePanel from "@/components/sideBar/DevourCartItemRemovePanel";

interface Props {
    show: boolean;
    toggle: () => void;
    brandMap?: BrandMap;
}

function DevourCart(props: Props): ReactElement {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const paths: Array<string> = location.pathname.split("/").filter(Boolean);
    const isCheckoutPage: boolean = paths[0] === "checkout";
    const [
        _windowWidth,
        windowHeight,
    ] = useWindowSize();

    const {menuOrderId, embeddedMenu, setCheckoutState} = useContext(RestaurantContext);
    const {data: menuOrder, refetch: refetchMenuOrder} = useMenuOrder(menuOrderId);
    const {data: restaurant} = useRestaurant(menuOrder?.business);
    const queryRestaurantMenu = useRestaurantMenu(restaurant?.id);
    const {
        mutateAsync: updateMenuOrderItems,
    } = useUpdateMenuOrderItems({
        menuOrder: menuOrder,
        menuOrderErrorModal: true,
        backgroundCallback: true,
    });
    const debounceLoadData = useCallback(_.debounce((newFrontendCart: Array<MenuOrderItem>) => updateLatestMenuOrderItems(newFrontendCart), 1000), []);

    const [
        handoff,
        setHandoff,
    ] = useState<HandoffOptions>(HandoffOptions.PICKUP);
    const [
        confirmClear,
        setConfirmClear,
    ] = useState<boolean>(false);
    const [
        showDiscountSelectionModal,
        setShowDiscountSelectionModal,
    ] = useState<boolean>(false);
    const [
        isCallbackFunctionsRunning,
        setIsCallbackFunctionsRunning,
    ] = useState<boolean>(false);
    const [
        isHandoffUpdating,
        setIsHandoffUpdating,
    ] = useState(false);
    const [
        frontEndCart,
        setFrontEndCart,
    ] = useState<Array<MenuOrderItem>>();
    const [
        removeMenuOrderItem,
        setRemoveMenuOrderItem,
    ] = useState<{ item: MenuOrderItem, index: number }>();

    const sumQuantity: number = frontEndCart?.reduce((accumulator, object) => {
        return accumulator + object.quantity;
    }, 0);
    const frontEndCartSubtotal = frontEndCart?.map(i => calculateMenuOrderItemPrice(i)).reduce((acc, curr) => acc + curr, 0);

    useEffect(() => {
        setHandoff(menuOrder?.handoff);
    }, [menuOrder?.handoff]);

    useEffect(() => {
        void updateLatestMenuOrderItems();
    }, [menuOrder?.callbackComplete]);

    useEffect(() => {
        // Update on first load OR items are added/deleted
        if (!frontEndCart || frontEndCart.length !== menuOrder?.orderItems.length) {
            setFrontEndCart(menuOrder?.orderItems);
        }
    }, [menuOrder?.orderItems]);

    useEffect(() => {
        // Update menu orders on debounce
        void debounceLoadData(frontEndCart);
    }, [frontEndCart]);

    async function updateLatestMenuOrderItems(newMenuOrder?: MenuOrderItem[], force: boolean = false): Promise<void> {

        const toUpdateMenuOrder = newMenuOrder || frontEndCart;
        const orderIdentical = checkIdenticalMenuOrders(menuOrder?.orderItems, toUpdateMenuOrder);

        if ((menuOrder && toUpdateMenuOrder && menuOrder.callbackComplete && !orderIdentical) || force) {
            await updateMenuOrderItems({
                orderItems: toUpdateMenuOrder,
            });
        }
    }

    function checkMenuOrderItemIds(item1: MenuOrderItem | MenuOrderSubItem, item2: MenuOrderItem | MenuOrderSubItem): boolean {
        if ("menuItemId" in item1 && "menuItemId" in item2) {
            return item1.menuItemId === item2.menuItemId;
        }
        if ("menuCustomizationItemId" in item1 && "menuCustomizationItemId" in item2) {
            return item1.menuCustomizationItemId === item2.menuCustomizationItemId;
        }
        // devourIQ
        return item1.externalId === item2.externalId;

    }

    /**
     * Checks if the 2 orders are identical
     *
     * @param order1
     * @param order2
     *
     * @return true if the 2 orders are identical
     */
    function checkIdenticalMenuOrders(order1: MenuOrderItem[] | MenuOrderSubItem[], order2: MenuOrderItem[] | MenuOrderSubItem[]): boolean {
        // both undefined
        if (order1 === order2) {
            return true;
        }

        // one is undefined and one is not
        if (!order1 ||
            !order2 ||
            order1.length !== order2.length
        ) {
            return false;
        }

        for (let i = 0; i < order1.length; i++) {
            if (!checkMenuOrderItemIds(order1[i], order2[i]) ||
                order1[i].name !== order2[i].name ||
                order1[i].notes !== order2[i].notes ||
                order1[i].quantity !== order2[i].quantity ||
                !checkIdenticalMenuOrders(order1[i].customizations, order2[i].customizations)
            ) {
                return false;
            }
        }

        return true;
    }

    function calculateMenuOrderItemPrice(item: MenuOrderItem | MenuOrderSubItem): number {
        return item.quantity * (item.price + item.customizations?.map(c => calculateMenuOrderItemPrice(c))
            .reduce((sum, curr) => sum + curr, 0) || 0);
    }


    useEffect(() => {
        if (props.show) {
            setConfirmClear(false);
            document.getElementById("drift-widget-wrapper")
                ?.classList.add("drift-frame-controller-hide");
        } else {
            document.getElementById("drift-widget-wrapper")
                ?.classList.remove("drift-frame-controller-hide");
        }

        return () => {
            document.getElementById("drift-widget-wrapper")?.classList
                .remove("drift-frame-controller-hide");
        };
    }, [props.show]);

    useEffect(() => {
        if (props.show && menuOrder && !menuOrder.callbackComplete) {
            void checkCallbackComplete();
        }
    }, [props.show, menuOrder]);

    /**
     * This is to get around forcing a refresh of the cart until menuOrder.callbackComplete
     * is complete, which was causing the restaurant context to trigger re-renders of the cart.
     */
    async function checkCallbackComplete(): Promise<void> {

        /*
         * Set a state to prevent this from being called via the useEffect if
         * function is already running.
         */
        if (isCallbackFunctionsRunning) {
            return;
        }

        setIsCallbackFunctionsRunning(true);

        try {
            let complete: boolean = false;

            /*
             * We essentially run a while loop that checks the status of the menuOrder
             * until the callback function is finished.
             */
            while (!complete) {
                complete = await timeoutGettingMenuOrder();
            }

            // After the while loop ends, we refresh the cart ONCE.
            dispatch(refreshMenuOrderCart());
            // temp fix to update RQ with non-stale version
            await refetchMenuOrder();

        } catch (e) {
            dispatch(await addError(e));

        } finally {
            // Reset the callback function running state.
            setIsCallbackFunctionsRunning(false);
        }
    }

    async function timeoutGettingMenuOrder(): Promise<boolean> {
        const timeoutInterval = 2000;

        // Set a lock which causes a 2-second wait between fetch times.
        await new Promise(resolve => setTimeout(resolve, timeoutInterval));

        const newMenuOrder = await refetchMenuOrder();

        return newMenuOrder?.data?.callbackComplete;
    }

    /**
     * Update the cart with new order items.
     * @param newOrderItemsBody
     */
    async function onUpdateCart(newOrderItemsBody: Array<MenuOrderItem>): Promise<void> {
        setFrontEndCart(newOrderItemsBody);
        // Actual update function is on debounce
    }

    function onCartClear(): void {
        setConfirmClear(false);
        void onUpdateCart([]);
        dispatch(removeDpayPriceExpiryTime());
        props.toggle();
        if (isCheckoutPage) {
            navigate(`/${restaurant?.url}/${menuOrder?.address?.placeId}`);
        }
    }

    function onRemoveItem(index: number): void {
        const newOrderItemsBody: Array<MenuOrderItem> = cloneDeep(frontEndCart);
        setRemoveMenuOrderItem(undefined);
        newOrderItemsBody.splice(index, 1);
        if (newOrderItemsBody.length > 0) {
            // If there are still items in the cart, update it.
            void onUpdateCart(newOrderItemsBody);
        } else {
            // If no items exist anymore, remove the cart reference.
            onCartClear();
        }
    }

    function onUpdateQuantity(index: number, quantity: number): void {
        const newOrderItemsBody: Array<MenuOrderItem> = cloneDeep(frontEndCart);
        newOrderItemsBody[index].quantity = quantity;

        if (newOrderItemsBody.length) {
            // If there are still items in the cart, update it.
            void onUpdateCart(newOrderItemsBody);
        } else {
            // If no items exist anymore, remove the cart reference.
            onCartClear();
        }
    }

    function renderCartEmpty(): JSX.Element {
        return null;
    }

    function toggleDiscountSelectionModal() {
        setShowDiscountSelectionModal(!showDiscountSelectionModal);
    }

    function onExternalUpdate(menuOrder: MenuOrder) {
        setFrontEndCart(menuOrder?.orderItems);
    }

    function renderCartDetails(): JSX.Element {
        const mainPromoApplied = props?.brandMap?.mainPromo?.id && menuOrder.discounts.map(discount => discount.reference).includes(props?.brandMap?.mainPromo?.id);

        return (
            <>
                <div className="devour-cart-panel_details">
                    <CheckoutDiscountSelectionModal
                        isOpen={showDiscountSelectionModal}
                        onClose={toggleDiscountSelectionModal}
                    />
                    <div className="devour-cart-panel_list">
                        <DevourCartItemRemovePanel
                            menuOrderItem={removeMenuOrderItem}
                            onRemoveItem={onRemoveItem}
                            onClose={() => setRemoveMenuOrderItem(undefined)}
                        />
                        {frontEndCart?.map((orderItem, index) => {
                            return (
                                <DevourCartCard
                                    key={`order-item-${index}`}
                                    menuOrderItem={orderItem}
                                    index={index}
                                    onRemoveItem={() => setRemoveMenuOrderItem({
                                        item: orderItem,
                                        index: index,
                                    })}
                                    onUpdateQuantity={onUpdateQuantity}
                                    restaurantMenu={queryRestaurantMenu.data}
                                    onExternalUpdate={onExternalUpdate}
                                />
                            );
                        })}
                        {mainPromoApplied && renderBrandMapPromoCard()}
                    </div>

                    <hr/>

                    <CheckoutSummaryTotals subtotal={frontEndCartSubtotal}/>

                </div>
            </>
        );
    }

    function renderBrandMapPromoCard(): ReactElement {
        const appliedDiscount = menuOrder.discounts.find(discount => discount.reference === props?.brandMap?.mainPromo?.id);
        if (appliedDiscount) {
            return <BrandCartPromoCard brandMap={props.brandMap}/>;
        }
    }

    async function triggerMenuOrderUpdate(): Promise<void> {
        dispatch(incrementLoading());
        try {
            await new MenuOrdersApi(getConfig()).checkoutMenuOrder({
                id: menuOrder.id,
            });
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(refreshMenuOrderCart());
            dispatch(decrementLoading());
        }
    }

    function onAddItems() {
        props.toggle();
        if (embeddedMenu) {
            setCheckoutState(BrandMapStates.ORDER_IN_PROGRESS);
        }
    }

    if (!queryRestaurantMenu.data) {
        return null;
    }

    return (
        <>
            <div
                className={classNames("devour-cart-overlay", {
                    "is-active": props.show,
                })}
                onClick={props.toggle}
            />
            <div
                className={classNames("devour-cart-panel", {
                    "is-active": props.show,
                })}
                style={{
                    height: windowHeight,
                }}
            >
                <div className="devour-cart-panel_top">
                    <div className="devour-cart-panel_header">
                        <div className="devour-cart-panel_header_back">
                            <button
                                className="devour-cart-panel_header_back_button"
                                onClick={props.toggle}
                            >
                                <HiArrowLeft/>
                            </button>
                        </div>
                        <div>
                            <div className="devour-cart-panel_header_title">
                                {!menuOrder || menuOrder?.orderItems.length === 0
                                    ? <h4>
                                        Your cart
                                    </h4>
                                    : <>
                                        <h4>
                                            {restaurant?.name}
                                        </h4>
                                    </>
                                }
                            </div>
                            <div className="devour-cart-panel_header_address">
                                {handoff === HandoffOptions.DELIVERY
                                    ? `Delivery to ${menuOrder?.address?.line1}`
                                    : handoff === HandoffOptions.PICKUP
                                        ? `Pickup at ${restaurant?.address?.line1}`
                                        : ""
                                }
                            </div>
                        </div>
                        <div className="devour-cart-panel_handoff_container">
                            {menuOrder
                                ? <CheckoutDetailsHandoff
                                    backToRestaurantPageOnAddressUpdate={false}
                                    onDone={triggerMenuOrderUpdate}
                                    menuOrderId={menuOrder.id}
                                    isHandoffUpdating={isHandoffUpdating}
                                    setIsHandoffUpdating={setIsHandoffUpdating}
                                />
                                : <RestaurantHandoffOptions/>
                            }
                        </div>
                    </div>
                    <div className="devour-cart-panel_header_quantity">
                        <span className={classNames(
                            "item-count",
                            {"empty-cart": !sumQuantity},
                        )}>
                            {sumQuantity ?? 0} {sumQuantity === 1 ? "Item" : "Items"}
                        </span>
                        {!sumQuantity
                            ? <span>
                                <span className="clear-cart-disabled">
                                    <TbTrashXFilled/>
                                </span>
                            </span>
                            : <span>
                                {!confirmClear
                                    ? <span className="clear-cart" onClick={() => setConfirmClear(true)}>
                                        <TbTrashXFilled/>
                                    </span>
                                    : <span className="clear-cart-confirm" onClick={onCartClear}>
                                        <TbTrashXFilled/> Clear Cart
                                    </span>
                                }
                            </span>
                        }
                    </div>
                    {menuOrder?.orderItems.length > 0 && renderCartDetails()}

                </div>
                {(!menuOrder || menuOrder?.orderItems.length === 0) && renderCartEmpty()}

                <div
                    className={classNames("devour-cart-panel_bottom", {
                        "devour-cart-panel_bottom_tablet-sticky-adjust": isTablet && !location.pathname.includes("checkout"),
                    })}
                >
                    {isCheckoutPage &&
                        <FrameButton
                            <LinkProps>
                            color="purple-outline"
                            size="large"
                            to={`${restaurant?.url}/${menuOrder?.address?.placeId}`}
                        >
                            Add Items
                        </FrameButton>
                    }
                    <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        className="devour-cart-panel_add-items"
                        color="gray-light"
                        size="large"
                        showSpinner={true}
                        onClick={onAddItems}
                    >
                        <HiPlus size=".5em"/> Add {menuOrder?.orderItems.length > 0 && "More"} Items
                    </FrameButton>
                    <CartCheckoutButton
                        toggle={props.toggle}
                        preCheckoutAction={() => void updateLatestMenuOrderItems(frontEndCart)}
                        disabled={!menuOrder?.orderItems.length}
                        subtotal={frontEndCartSubtotal}
                    />
                </div>
            </div>
        </>
    );
}

export default DevourCart;
